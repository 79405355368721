/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { mapStateToProps } from 'utilities';
import { FormInput } from 'components';


class SignUpForm extends PureComponent {
  constructor() {
    super();

    this.state = {
      errors: [],
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }


  /**
   * Handle form submit event
   * @param e
   */
  handleFormSubmit(e) {
    e.preventDefault();
    const FORM = e.currentTarget;

    if (FORM instanceof HTMLFormElement === false || !FORM.checkValidity()) return;

    // TODO
    // const FORM_DATA = new FormData(FORM);
    window.alert('Need info for where this form should submit to');
  }


  render() {
    const { errors } = this.state;

    return (
      <div className="c-newsletter">
        <img src="/images/intrepid-company-logo-white.png"
            alt="intrepid-company-logo"
            className='intrepid-company-logo'
        />
        <img src="/images/B-Corp-Logo-White-RGB.png"
            alt="b-corp-logo"
            className='b-corp-logo'
        />
        <div className="c-newsletter-form">
          <div className="c-newsletter-form__header">
            <p>Sign up to get the latest Haka news and offers</p>
          </div>
          <div className="c-newsletter-form__wrapper">
            <form acceptCharset="UTF-8" action="https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                  className="infusion-form" method="POST">
              <input type="hidden" name="oid" value="00D1y0000008jmd" />
              <input type="hidden" name="lead_source" value="Web - hakatours.com" />
              <input type="hidden" name="recordType" value="0122v000001q0nm" />
              <input type="hidden" name="00N2v00000SoEoJ" value="Newsletter" />
              <input type="hidden" name="00N2v00000P4H1w" value="https://hakatours.com" />
              <input type="hidden" name="retURL" value="https://hakatours.com/?subscribe=true" />
              <input type="hidden" name="00N2v00000P4H1x" value="1" />
              <div className="infusion-field">
                <label htmlFor="email">Email </label>
                <input required className="infusion-field-input" id="email" name="email" placeholder="Enter your email address " type="text"/>
              </div>
              <div className="infusion-submit">
                <button className="infusion-recaptcha c-button c-button--border c-button--small" id="submit" type="submit">Subscribe</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(SignUpForm);
